@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

.linkHint::after {
  content: "→";
  margin-left: 0.3rem;
  display: inline-block;
  font-size: 70%;
}
.linkHintReverse::before {
  content: "←";
  margin-right: 0.3rem;
  display: inline-block;
  font-size: 70%;
}
